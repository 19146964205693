import React from 'react'
import PropTypes from 'prop-types'
import styles from './CategoryHero.module.scss'
import classNames from 'classnames'

const CategoryHero = ({ category }) => {
    const categories = {
        mattresses: 'Handcrafted mattresses for every sleep style',
        furniture: 'Handcrafted furniture & decor that enrich your bedroom space',
        bedding: 'Blissful bedding, bath & sleep essentials'
    }
    const categoryText = categories[category]
    if (!categoryText) {
        return null
    }

    const containerClasses = classNames('container', styles.isMobile)
    const heroClasses = classNames(styles.heroImage, styles[`heroImage__${category}`])

    return (
        <div className={containerClasses}>
            <div className={heroClasses}>
                <h1>{categoryText}</h1>
            </div>
        </div>
    )
}

CategoryHero.propTypes = {
    category: PropTypes.string,
}

export default CategoryHero
