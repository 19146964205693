import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { subcategoriesConfig } from './config'

import { generateAssetURLs } from '@saatva-bits/pattern-library.utils.product'
import PictureImgix from '../PictureImgix'

import styles from './CategorySubcategories.module.scss'

const CategorySubcategories = ({ category }) => {
    const subcategoryData = subcategoriesConfig[category]

    if (!subcategoryData) {
        return null
    }

    return (
        <div className={classNames('container', styles.container)}>
            <div
                className={classNames(
                    styles.subcategories,
                    { [styles.scrollX]: subcategoryData.length > 8 }
                )}
            >
                {subcategoryData.map(subcategory => {
                    const image = generateAssetURLs(
                        subcategory.image.productCode,
                        subcategory.image,
                        subcategory.image.selectedOptions,
                        '1-1',
                        true
                    )?.[0]

                    return (
                        <a
                            key={subcategory.title}
                            href={subcategory.url}
                            className={styles.subcategory}
                        >
                            <PictureImgix
                                folder={image.folder}
                                name={image.filename}
                                alt={subcategory.image.altTag}
                                className={styles.imagePicture}
                                imageClassName={styles.image}
                                widths={{
                                    mobile: 98,
                                    tablet: 98,
                                    desktop: 98
                                }}
                                prefixOverride={{
                                    mobile: 'none',
                                    tablet: 'none',
                                    desktop: 'none'
                                }}
                            />
                            <div className={styles.title}>{subcategory.title}</div>
                        </a>
                    )
                })}
            </div>
        </div>
    )
}

CategorySubcategories.propTypes = {
    category: PropTypes.string.isRequired
}

export default CategorySubcategories
