import PropTypes from 'prop-types'

import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { useFaqContext } from '@saatva-bits/pattern-library.modules.faq'

import styles from './AuxContentTile.module.scss'

const AuxContentTile = ({
    title,
    subtitle,
    disclaimer,
    ctaFaq,
    ctaText,
    svg,
}) => {
    const { scrollToAccordionContent } = useFaqContext()

    const handleAccordionScroll = (e) => {
        e.preventDefault()
        scrollToAccordionContent(ctaFaq)
    }

    return (
        <div className={styles.tile}>
            <div className={styles.content}>
                { svg && <div className={styles.svgRow}>{ <SvgSprite className={styles.icon} spriteID={svg} /> }</div>}
                { title && <h2 className={styles.title}>{ title }</h2>}
                { subtitle && <div className={styles.subtitle}>{ subtitle }</div>}
                { disclaimer && <div className={styles.disclaimer}>{ disclaimer }</div>}
                { ctaFaq && <a className={styles.cta} onClick={handleAccordionScroll}>{ ctaText || 'Learn more' }</a>}
            </div>
        </div>
    )
}

AuxContentTile.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    disclaimer: PropTypes.string,
    ctaLink: PropTypes.string,
    ctaTest: PropTypes.string,
    svg: PropTypes.string
}

const ValuePropsTile = ({
    valueProps
}) => {
    return (
        <div className={styles.valuePropTile}>
            <div className={styles.valuePropContent}>
                {valueProps.map(({ svg, text }, i) => {
                    return (
                        <div key={i} className={styles.valueProp}>
                            <div className={styles.leftColumn}>
                                <div className={styles.valuePropSvgRow}>{ <SvgSprite className={styles.icon} spriteID={svg} /> }</div>
                            </div>
                            <div className={styles.rightColumn}>
                                <h2 className={styles.valuePropText}>{ text }</h2>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

ValuePropsTile.propTypes = {
    valueProps: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        svg: PropTypes.string
    }))
}

export {
    AuxContentTile,
    ValuePropsTile
}
