import styles from './FormCheckboxV2.module.scss'
import Image from 'next/image'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const FormCheckboxV2 = ({ id, className, onChange, checked, label, disabled, value = '', href, anchorDataSelector }) => {
    const icon = checked ? 'remove-x-icon-thin.svg' : 'add-icon-thin.svg'
    const checkboxClasses = disabled ? classNames(styles.formCheckbox, className, styles.optionDisabled) : classNames(styles.formCheckbox, className)
    return (
        <a href={href} data-selector={anchorDataSelector} className={checkboxClasses}>
            <div className={checkboxClasses}>
                <label htmlFor={id} className={checked ? styles.isChecked : styles.isNotChecked}>
                    <input id={id} className={className} type="checkbox" name="checkbox" disabled={disabled} checked={checked} value={value} onChange={onChange} />
                    {label}
                    <Image
                        className={styles.actionIcon}
                        src={`https://${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}/static/assets/icons/${icon}`}
                        alt={checked ? 'Remove filter' : 'Add filter'}
                        width={10}
                        height={10}
                    />
                </label>
            </div>
        </a>
    )
}

FormCheckboxV2.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string
}

export default FormCheckboxV2