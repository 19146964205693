import classNames from 'classnames'

import styles from './QuizBedframe.module.scss'
import { useEffect, useRef, useState } from 'react'

const Quiz = () => {
    const sectionClasses = classNames('section', styles.section)
    const containerClasses = classNames('container', styles.quizBackgroundImage, styles.quizContainer)
    const iframeRef = useRef(null)
    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {
        if (!firstRender) {
            const getIframeHeight = (event) => {
                if (event.data.action === 'updateIframeHeight' && !firstRender) {
                    const dataLayerEvent = window.dataLayer && window.dataLayer.length > 0 ? window.dataLayer[window.dataLayer.length - 1] : null
                    const quizCompleted = dataLayerEvent.iframe?.quizQuestion === 'Email Capture'

                    if (quizCompleted) {
                        iframeRef.current.classList.add(styles.finalQuizHeight)
                    }
                }
            }

            window.addEventListener('message', getIframeHeight)

            return () => {
                window.removeEventListener('message', getIframeHeight)
            }
        }
        setFirstRender(false)
    }, [firstRender])

    return (
        <section className={sectionClasses}>
            <div className={containerClasses}>
                <iframe
                    title="jebbit iframe quiz"
                    data-selector="jebbit-iframe"
                    className={styles.quiz}
                    src="https://saatva.jebbit.com/7ftuargs?L=Full+Page&deferred=true"
                    seamless={true}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen={true}
                    ref={iframeRef}
                    onLoad={`function embedJebbit(t){function e(t){var e='attach'===t?window.addEventListener:window.removeEventListener;e('DOMContentLoaded',i,!1),e('load',i,!1),e('scroll',i,!1),e('resize',i,!1)}var n=document.querySelector(t);function i(){(function(t){var e=t.getBoundingClientRect(),n=t.clientHeight/2,i=t.clientWidth/2;return e.top>=0&&e.left>=0&&e.top<=(window.innerHeight||document.documentElement.clientHeight)-n&&e.left<=(window.innerWidth||document.documentElement.clientWidth)-i})(n)&&n.contentWindow.postMessage('startJebbitCampaign','*')}window.addEventListener('message',function(t){t&&('jebbitSessionCreated'===t.data?e('remove'):'jebbitCampaignLoaded'===t.data?i():t.data&&'redirect'===t.data.action&&t.data.options&&t.data.options.link&&(window.location.href=t.data.options.link))},!1),e('attach')}embedJebbit('.jebbit-iframe');"></iframe>https://quiz.saatva.com/5kmeejug?L=Full+Page&deferred=true " seamless="true" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="width:100%;min-height:600px;" onload="function embedJebbit(t){function e(t){var e='attach'===t?window.addEventListener:window.removeEventListener;e('DOMContentLoaded',i,!1),e('load',i,!1),e('scroll',i,!1),e('resize',i,!1)}var n=document.querySelector(t);function i(){(function(t){var e=t.getBoundingClientRect(),n=t.clientHeight/2,i=t.clientWidth/2;return e.top>=0&&e.left>=0&&e.top<=(window.innerHeight||document.documentElement.clientHeight)-n&&e.left<=(window.innerWidth||document.documentElement.clientWidth)-i})(n)&&n.contentWindow.postMessage('startJebbitCampaign','*')}window.addEventListener('message',function(t){t&&('jebbitSessionCreated'===t.data?e('remove'):'jebbitCampaignLoaded'===t.data?i():t.data&&'redirect'===t.data.action&&t.data.options&&t.data.options.link&&(window.location.href=t.data.options.link))},!1),e('attach')}embedJebbit('.jebbit-iframe');`}
                />
            </div>
        </section>
    )
}

export default Quiz
