import React, { useState } from 'react'
import CATEGORIES from '@/temp-configs/category-constants'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { Button } from '@saatva-bits/pattern-library.components.button'

import { useFiltersState, useFiltersData } from '@/contexts/filters'
import {
    applySort,
    FILTER_PROPERTIES,
    getFilterMetaData,
    getSelectedFilters,
    getValuesByProperty,
    applyVariantFiltering
} from '@/utils/filters'
import { affirmRefresh } from '@/utils/affirm'
import FilterSection from '../FilterSection/FilterSection'
import ProductTiles from '../ProductTiles/ProductTiles'

import styles from './FilterContent.module.scss'

const FilterContent = ({ products, initialLoadedItems = 15, category }) => {
    const { filterValues, selectedValues, sortBy, clearFilters } = useFiltersState()
    const { metaData, filterOptions } = useFiltersData()
    const { isMobile } = useBreakpoints('desktop')
    const { getDiscounts } = useGetDiscounts()
    const [loadMore, setLoadMore] = useState(initialLoadedItems)

    const { pageheader } = getFilterMetaData(selectedValues, metaData)

    // Values used for personalization
    const valuesGroups = {
        selectedSizes: getValuesByProperty(FILTER_PROPERTIES.sizes, selectedValues),
        selectedColors: getValuesByProperty(FILTER_PROPERTIES.colors, selectedValues),
        selectedMaterials: getValuesByProperty(FILTER_PROPERTIES.materials, selectedValues)
    }

    const selectedFilters = getSelectedFilters(filterOptions, Object.values(filterValues))
    const filteredProducts = applyVariantFiltering(products, selectedFilters, getDiscounts)

    const sortedList = applySort(filteredProducts, sortBy)

    let productList = isMobile ? sortedList.slice(0, loadMore) : sortedList
    const productListLength = sortedList.length

    const handleLoadMoreOnClick = () => {
        setLoadMore(productListLength)
        affirmRefresh(100)
    }

    const headerClassName = classNames('t-heading1 t-color', styles.pageheader)
    const hideSwatchesImages = category === CATEGORIES.BUNDLES

    return (
        <section className="section u-paddingBottom--sm u-bgColor--contrastWhite">
            <div className="container">
                <h1 className={headerClassName}>{pageheader}</h1>
                <FilterSection totalResults={productListLength} products={products} selectedFilters={selectedFilters} />
                <div className={classNames('row u-flexJustify--center u-marginBottom--2xl', styles.productTileContainer)}>
                    <ProductTiles
                        products={productList}
                        valuesGroups={valuesGroups}
                        clearFilters={clearFilters}
                        hideSwatchesImages={hideSwatchesImages}
                    />
                </div>
                {isMobile && loadMore < productListLength &&
                    <Button
                        kind="primary"
                        className={styles.loadMoreBtn}
                        onClick={handleLoadMoreOnClick}
                    >
                        Load More
                    </Button>
                }
            </div>
        </section>
    )
}

FilterContent.propTypes = {
    products: PropTypes.array,
    initialLoadedItems: PropTypes.number,
    category: PropTypes.string
}

export default FilterContent
