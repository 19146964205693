import PropTypes from 'prop-types'

const SvgGoodForTheWorld = ({ className, stroke }) => {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" className={className}>
            <path d="m42.09,40c-2.87,4.79-8.1,8-14.09,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m42.59,24c1.18,2.26,1.85,4.83,1.85,7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m11.56,31.56c0-2.73.68-5.3,1.85-7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m13.41,24c2.74-5.27,8.24-8.88,14.59-8.88s11.85,3.61,14.59,8.88" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m28,48c-5.99,0-11.21-3.21-14.09-8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m42.09,40c1.48-2.47,2.35-5.35,2.35-8.44,0,0,0,0,0,0" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m11.56,31.56s0,0,0,0c0,3.09.87,5.97,2.35,8.44" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="28" y1="15.12" x2="28" y2="11.46" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="28" y1="11.46" x2="28" y2="7.98" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m28,11.46c0-1.38-.72-2.72-2-3.46-1.28-.74-2.8-.69-4,0,0,1.38.72,2.72,2,3.46,1.28.74,2.8.69,4,0" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m33.03,4.14c-1.34-.36-2.81-.01-3.86,1.03-1.05,1.05-1.39,2.52-1.03,3.86,1.34.36,2.81.01,3.86-1.03,1.05-1.05,1.39-2.52,1.03-3.86Z" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m21.08,24c-.65,2.24-1.03,4.81-1.07,7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m21.08,40c1.38,4.78,3.96,8,6.92,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m20.01,31.56c0,.15-.01.29-.01.44,0,2.92.4,5.64,1.08,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m34.92,24c-1.38-4.78-3.96-8-6.92-8s-5.54,3.22-6.92,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m34.92,40c-1.38,4.78-3.96,8-6.92,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m35.99,31.56c-.04-2.75-.42-5.32-1.07-7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m34.92,40c.68-2.36,1.08-5.08,1.08-8,0-.15,0-.29-.01-.44" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="20.01" y1="31.56" x2="11.56" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="35.99" y1="31.56" x2="20.01" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="44.44" y1="31.56" x2="44.44" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="35.99" y1="31.56" x2="44.44" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="11.56" y1="31.56" x2="11.56" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="21.08" y1="40" x2="34.92" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="21.08" y1="40" x2="13.91" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="13.91" y1="40" x2="13.89" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="42.09" y1="40" x2="42.11" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="34.92" y1="40" x2="42.09" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="34.92" y1="24" x2="42.59" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="21.08" y1="24" x2="13.41" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="34.92" y1="24" x2="21.08" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="42.59" y1="24" x2="42.6" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="13.41" y1="24" x2="13.4" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
        </svg>
    )
}

SvgGoodForTheWorld.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string
}

const SvgHypoallergenic = ({ className, stroke = '#D5AA63', fill = 'none' }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M32 48C34.21 48 36 46.21 36 44C36 41.79 34.21 40 32 40H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32 8C34.21 8 36 9.79 36 12C36 14.21 34.21 16 32 16H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44 16C46.21 16 48 17.79 48 20C48 22.21 46.21 24 44 24H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44 40C46.21 40 48 38.21 48 36C48 33.79 46.21 32 44 32H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

SvgHypoallergenic.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string,
    fill: PropTypes.string
}

const SvgHypoallergenicFeather = ({ className, stroke = '#B19780', fill = 'none' }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M20.28 23.83C17.56 28.54 12 38 12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36 31.83C37.53 30.45 38.89 29.09 40 27.83V23.83H43.72C45.89 20.08 47.32 15.85 47.81 11.34C48.02 9.43002 46.4 7.81002 44.49 8.02002C36.35 8.91002 29.13 12.84 24 18.66" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.07 35.83C25.12 40.19 17.97 43.83 12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L40 15.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 47.83L12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L44 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L12 11.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

SvgHypoallergenicFeather.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string,
    fill: PropTypes.string
}

const SvgFairTrade = ({ className, stroke = '#B19780', fill = 'none' }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M28 43V29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 11C26 9.9 26.9 9 28 9C29.1 9 30 9.9 30 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30 11H41.64" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30 11C30 12.1 29.1 13 28 13" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.3601 11H44.0001" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.64 11H12" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3601 11H26.0001" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 11C26 12.1 26.9 13 28 13" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 29V13" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.6399 11L41.9999 9L42.3599 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.3601 11L45.6401 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.6401 11L38.3601 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.6399 11H42.3599" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.64 29H48C48 32.31 45.31 35 42 35C38.69 35 36 32.31 36 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36 29H38.36" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.6401 29H38.3601" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3599 11L13.9999 9L13.6399 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3601 29L13.6401 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.6401 29L14.3601 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3599 11H13.6399" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.36 29H8C8 32.31 10.69 35 14 35C17.31 35 20 32.31 20 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.9999 29H17.6399" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3601 29H17.6401" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 43H20V47H36V43H28Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

SvgFairTrade.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string,
    fill: PropTypes.string
}

const SvgWithoutMarkups = ({
    className,
    height,
    width,
    fill = '#c19678'
}) => {
    return (
        <svg width={ width } height={ height } className={ className } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.02 42.02">
            <g id="c">
                <g>
                    <path d="M21.39,15.04c1.66,0,3.04,1.38,3.04,3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M21.39,27.2c-1.68,0-3.04-1.36-3.04-3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="27.2" x2="21.39" y2="28.72" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="15.04" x2="21.39" y2="13.52" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="15.04" x2="21.39" y2="21.12" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M21.39,15.04c-1.68,0-3.04,1.36-3.04,3.04s1.36,3.04,3.04,3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="21.12" x2="21.39" y2="27.2" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M21.39,21.12c1.68,0,3.04,1.36,3.04,3.04s-1.36,3.04-3.04,3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                </g>
                <polyline points="37.39 8.63 37.39 21.6 17.6 41.39 .62 24.42 20.42 4.62 37.39 4.62 37.39 8.63" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M34.57,7.45c-.72-.72-1.17-1.72-1.17-2.83" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M37.39,8.62h0" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M33.39,4.63h0c0-2.21,1.79-4,4-4s4,1.79,4,4-1.79,4-4,4" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            </g>
        </svg>
    )
}

const SvgSustainability = ({
    className,
    height,
    width,
    fill = '#c19678'
}) => {
    return (
        <svg width={ width } height={ height } className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 45.41 40">
            <g id="c">
                <g>
                    <path d="M5.66,20.41l-2.83-2.83-2.83,2.83,2.81-2.8-.03,2.39c-.02,1.58,.16,4.79,1.72,8,3.08,7.07,10.13,12,18.33,12s15.25-4.93,18.33-12" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M39.75,19.59l2.83,2.83,2.83-2.83-2.81,2.8,.03-2.39c.02-1.58-.16-4.79-1.72-8C37.83,4.93,30.78,0,22.58,0S7.33,4.93,4.25,12" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M22.7,18.43c1.79-1.03,2.99-2.97,2.99-5.19s-1.21-4.15-2.99-5.19c-1.79,1.03-2.99,2.97-2.99,5.19s1.21,4.15,2.99,5.19" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="22.7" y1="28.13" x2="22.7" y2="32" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="22.7" y1="28.13" x2="22.7" y2="18.43" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="22.7" y1="18.43" x2="22.7" y2="14.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M18.11,23.54c.53-1.99,.02-4.21-1.55-5.78-1.57-1.57-3.79-2.08-5.78-1.55-.53,1.99-.02,4.21,1.55,5.78,1.57,1.57,3.79,2.08,5.78,1.55,0,0,0,0,0,0" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="15" y1="20.43" x2="18.11" y2="23.54" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <polyline points="22.7 28.13 18.11 23.54 18.11 23.54" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M27.3,23.54s0,0,0,0c1.99,.53,4.21,.02,5.78-1.55,1.57-1.57,2.08-3.79,1.55-5.78-1.99-.53-4.21-.02-5.78,1.55-1.57,1.57-2.08,3.79-1.55,5.78" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="27.3" y1="23.54" x2="30.4" y2="20.43" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="27.3" y1="23.54" x2="22.7" y2="28.13" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                </g>
            </g>
        </svg>
    )
}

const SvgHomeTrial = (props) => {
    return (
        <svg viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" {...props}>
            <title>home-trial</title>
            <line x1="44" y1="24" x2="44" y2="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="12" y1="48" x2="12" y2="24" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="44 24 48 24 40 12" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="36 12 16 12 8 24 12 24" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="44" y1="24" x2="12" y2="24" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="32 48 32 36 24 36 24 48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="40 12 40 8 36 8 36 12" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="40" y1="12" x2="36" y2="12" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="48" y1="48" x2="44" y2="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="12" y1="48" x2="8" y2="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="12" y1="48" x2="24" y2="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="44" y1="48" x2="32" y2="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="24" y1="48" x2="32" y2="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
        </svg>
    )
}

const SvgWhiteGlove = (props) => {
    return (
        <svg viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" {...props}>
            <title>white-glove</title>
            <polyline points="16 27.75 16 24 4 24 4 44 16 44 16 40.25" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="16" y1="27.75" x2="16" y2="40.25" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M34.74,31.91c1.08,0,2.01-.82,2.09-1.9,.09-1.24-.75-1.83-1.71-2.25-.02,0-6.03-2.34-9.07-3.52-1.1-.43-2.32-.37-3.37,.16l-6.68,3.34" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="34.74" y1="31.91" x2="24.34" y2="31.91" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M34.74,31.91h1.18c.6,0,1.19-.18,1.69-.51l9.66-6.44c1.21-.8,2.81-.65,3.84,.38,1.28,1.28,1.17,3.39-.25,4.53l-17.01,13.61c-.74,.59-1.72,.81-2.64,.58l-15.21-3.8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="48 12 44 12 48 8 44 8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="32 12 28 12 32 8 28 8" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="40 20 36 20 40 16 36 16" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <circle cx="10" cy="38" r="2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
        </svg>
    )
}

const SvgMattressRemoval = (props) => {
    return (
        <svg viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" {...props}>
            <title>mattress-removal</title>
            <polyline points="36 24 40 24 44 28 52 32 52 40 48 40" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="40" y1="40" x2="36" y2="40" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M48,40c0,2.21-1.79,4-4,4s-4-1.79-4-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M48,40c0-2.21-1.79-4-4-4s-4,1.79-4,4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="32" y1="28" x2="20" y2="28" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="32" y1="24" x2="16" y2="24" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="12" y1="20" x2="32" y2="20" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="36 24 36 12 4 12 4 40 8 40" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="36" y1="40" x2="16" y2="40" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="36" y1="24" x2="36" y2="40" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M16,40c0,2.21-1.79,4-4,4s-4-1.79-4-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M16,40c0-2.21-1.79-4-4-4s-4,1.79-4,4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
        </svg>
    )
}

const SvgWarranty = (props) => {
    return (
        <svg viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" {...props}>
            <title>warranty</title>
            <polyline points="44 40.35 44 8 12 8 12 48 40.35 48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="44" y1="40.35" x2="44" y2="40.35" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="40.35" y1="48" x2="40.35" y2="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="16" y1="20" x2="40" y2="20" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="40" y1="24" x2="16" y2="24" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="40" y1="32" x2="16" y2="32" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="40" y1="28" x2="16" y2="28" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <line x1="36" y1="36" x2="16" y2="36" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <polyline points="49 44 45 48 43 46" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M44,40.35c.63-.22,1.3-.35,2-.35,3.31,0,6,2.69,6,6s-2.69,6-6,6c-2.61,0-4.83-1.67-5.65-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            <path d="M44,40.35c-2.33,.82-4,3.04-4,5.65,0,.7,.13,1.37,.35,2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
        </svg>
    )
}

export const tiles = {
    furniture: [
        {
            title: 'We’ll do the heavy lifting',
            subtitle: 'FREE white glove delivery on all furniture & rugs',
            ctaText: 'Learn more',
            ctaFaq: 'what-is-white-glove-delivery',
            Svg: SvgWhiteGlove,
            svg: 'icon-setup'
        },
        {
            title: '365-night home trial',
            subtitle: 'Enjoy a generous trial period on our bedroom furniture.*',
            disclaimer: '*Excludes adjustable bases, which are not eligible for returns or exchanges',
            ctaText: 'Learn more',
            ctaFaq: 'return-exchange-bedroom-furniture',
            Svg: SvgHomeTrial,
            svg: 'icon-house'
        }
    ],
    bedding: [
        {
            valueProps: [
                {
                    text: '45-day free returns',
                    Svg: SvgHomeTrial,
                    svg: 'icon-house'
                },
                {
                    text: 'Free shipping',
                    Svg: SvgMattressRemoval,
                    svg: 'icon-truck'
                },
                {
                    text: '1-year limited warranty',
                    Svg: SvgWarranty,
                    svg: 'icon-warranty'
                }
            ]
        },
        {
            title: 'Hypoallergenic for sensitive sleepers',
            subtitle: 'Natural fabrics mean more restful nights for sleepers with sensitive skin & allergies.',
            Svg: SvgHypoallergenicFeather,
            svg: 'icon-hypoallergenic'
        },
        {
            title: 'Naturally breathable',
            subtitle: 'Natural fibers offer cozy comfort without feeling hot or stuffy.',
            svg: 'icon-breathable'
        },
        {
            title: 'Better for you & the planet',
            subtitle: 'We only use fibers from crops grown without GMOs and other harmful chemicals.',
            Svg: SvgSustainability,
            svg: 'icon-natural'
        },
        {
            title: 'Responsibly sourced & crafted',
            subtitle: 'Every step upholds the highest standard of economic, social, and environmental responsibility.',
            Svg: SvgGoodForTheWorld,
            svg: 'icon-world-good'
        },
        {
            title: 'Make it a pillow pair & save',
            subtitle: 'Get $40 off every pair of queen or $60 off every pair of king pillows.',
            Svg: SvgWithoutMarkups,
            svg: 'icon-money-tag'
        }
    ]
}
