
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'
import FormCheckboxV2 from '@/components/FormCheckboxV2/FormCheckboxV2'
import { useFiltersData } from '@/contexts/filters'
import {
    applyVariantFiltering
} from '@/utils/filters'

const createFilterLink = (pathname, filterKey, filterValue, sortValue) => {
    const parameters = new URLSearchParams()

    parameters.set(filterKey, filterValue)
    sortValue && parameters.set('sorting', sortValue)

    return `${pathname}${parameters.toString().length > 0 ? '?' : ''}${parameters}`
}

const calculateFilterQuantity = (products, property, filter, selectedFilters) => {   
    if (!products || !property || !filter || !selectedFilters) {
        return 0
    }

    const newFilters = selectedFilters.map(selected => {
        const filtersAdded = Object.assign({}, selected)
        let matchingValues = selected.matchingValues
        if (selected.property === property) {
            matchingValues = [...filter.matchingValues]
        }
        filtersAdded.matchingValues = matchingValues
        return filtersAdded
    })
    
    const filteredProducts = applyVariantFiltering(products, newFilters, null)
    return filteredProducts.length
}

const FormCheckboxGroup = ({
    className,
    dataSrc,
    name = '',
    initialValues,
    required = 0,
    onSelectionUpdate = () => { },
    checkboxClassName,
    useV2 = false,
    sortValue = '',
    filter,
    products,
    selectedFilters,
    ...other
}) => {

    const { category } = useFiltersData()

    const [state, setState] = useState({
        selectedValues: initialValues,
        isValid: true
    })

    useEffect(() => {
        if (initialValues)
            setState({
                selectedValues: initialValues,
                isValid: true
            })
    }, [initialValues])


    const handleInputChange = (e) => {
        const target = e.target ? e.target : e
        const value = target.value

        if (!target.checked) {
            const selectedArr = [...state.selectedValues]

            const index = selectedArr.indexOf(value)
            selectedArr.splice(index, 1)

            setState({
                selectedValues: selectedArr,
                isValid: selectedArr.length >= required
            })
        } else {
            setState({
                isValid: state.selectedValues.length + 1 >= required,
                selectedValues: [...state.selectedValues, value]
            })
        }

        onSelectionUpdate({
            selectedValues: state.selectedValues,
            lastToggled: value,
            isValid: state.isValid
        })
    }

    const classes = classNames(className)

    let checkboxes

    if (useV2) {
        checkboxes = dataSrc.map((data) => {
            const quantity = calculateFilterQuantity(products, filter.property, data, selectedFilters)
            let label = data.label
            if (quantity > 0) {
                label = !data.disabled ? `${data.label} (${quantity})` : data.label
            }
            return (
                <FormCheckboxV2
                    {...data}
                    name={name}
                    onChange={handleInputChange}
                    checked={state.selectedValues.indexOf(data.value) !== -1}
                    key={`modal-anchor-${filter.property}-${data.value}`}
                    href={createFilterLink(category, filter.property, data.value, sortValue)}
                    anchorDataSelector={`check_box_filter_${data.value}`}
                    label={label}
                    disabled={data.disabled || quantity === 0}
                />
            )
        })
    } else {
        checkboxes = dataSrc.map((data, i) => {
            const quantity = calculateFilterQuantity(products, filter.property, data, selectedFilters)
            let label = data.label
            if (quantity > 0) {
                label = !data.disabled ? `${data.label} (${quantity})` : data.label
            }
            return (
                <a href={createFilterLink(category, filter.property, data.value, sortValue)} key={`anchor-${filter.property}-${data.value}`} data-selector={`check_box_filter_${data.value}`}>                    
                    <FormCheckbox
                        {...data}
                        key={`${i}`}
                        className={checkboxClassName}
                        name={name}
                        onChange={handleInputChange}
                        checked={state.selectedValues.indexOf(data.value) !== -1}
                        label={label}
                        disabled={data.disabled || quantity === 0}
                    />
                </a>
            )
        })
    }

    return (
        <div className={classes} {...other}>
            {checkboxes}
        </div>
    )
}

FormCheckboxGroup.propTypes = {
    /** Applies a custom class to the FormTextInput component's wrapping div. */
    className: PropTypes.string,
    /** Array of values to be showed as checkboxes. */
    dataSrc: PropTypes.array.isRequired,
    /** Array of selected values. */
    initialValues: PropTypes.array,
    /** Checkboxe's name attribute. */
    name: PropTypes.string,
    /** Prop that controls whether the input is a required field. */
    required: PropTypes.number,
    /** Handler to manage the checkbox selection. */
    onSelectionUpdate: PropTypes.func,
    /** Switches to V2 checkbox pills */
    useV2: PropTypes.bool,
    /** Passing link for SEO purposes */
    sortValue: PropTypes.string,
    /** Passing filters for link  */
    filter: PropTypes.any,
    /** Passing selected filters  */
    selectedFilters: PropTypes.any,
    /** Passing products array */
    products: PropTypes.array
}

export default FormCheckboxGroup
