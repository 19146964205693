import split from 'lodash/split'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import * as Sentry from '@sentry/node'

import * as Cache from '@saatva-bits/pattern-library.modules.cache'
import { getExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.utils'

import baseRouter from '@/utils/baseRouter'
import { buildServerSideContext, buildServerSideProps } from '@/utils/buildServerSide'
import { getPreviewCacheKey, getPrismicRef } from '@/utils/prismicPreview'
import {
    retrieveParams,
    getSelectedValues,
    getFilterMetaData,
    getFilterOptions,
    formatFilterProduct
} from '@/utils/filters'
import { sortProductsByPriority } from '@/utils/product'

import CATEGORIES from '@/temp-configs/category-constants'
import { SHORT_REVALIDATE } from '@/temp-configs/cache-constants'

import { getCatalogCategoryProducts, getTrendingItems } from '@/services/catalog/getCatalogProducts'
import { getFilterConfigByCategory } from '@/services/catalog/getFilterConfig'
import { getFiltersData } from '@/services/content/getFiltersData'
import { getFaqsList } from '@/services/content/getFaqsList'
import { getProductsData } from '@/services/content/getProductsData'
import { getReviewsList } from '@/services/content/getReviewsList'

import CategoryView from '@/views/Category'

const RECOMMENDATION_LISTS = {
    [CATEGORIES.MATTRESSES]: 'mattress-plp-recommended-products'
}

const FAQS_LISTS = {
    [CATEGORIES.MATTRESSES]: 'mattress-plp-faq-list',
    [CATEGORIES.FURNITURE]: 'furniture-and-decor-faq-list',
    [CATEGORIES.BEDDING]: 'bedding-plp-faq-list',
    [CATEGORIES.BUNDLES]: 'bundles-plp-faq-list'
}

const REVIEWS_LISTS = {
    [CATEGORIES.MATTRESSES]: 'mattresses',
    [CATEGORIES.FURNITURE]: 'furniture',
    [CATEGORIES.BEDDING]: 'bedding',
    [CATEGORIES.BUNDLES]: 'bundles'
}

const router = baseRouter().get(async (req, res) => {
    const category = req.params?.category
    const prismicRef = getPrismicRef(req)

    if (category === CATEGORIES.BUNDLES) {
        const { isV1 } = getExperiment(
            res.locals.experiments,
            'EXP.BUNDLE_TEST.DIS-162',
            res.locals.flagOverrides
        )
    
        if (!isV1) {
            return {
                redirect: {
                    destination: '/bedding',
                    permanent: false
                }
            }
        } 
    } else if (!category || !Object.values(CATEGORIES).includes(category)) {
        return {
            notFound: true
        }
    }

    let pageData = {
        title: 'Smarter Luxury Sleep',
        description: 'The highest quality handcrafted mattresses, bedding & furniture at the most comfortable prices.',
        pageType: 'category',
        excludedScripts: [],
        category,
        filterContextData: {
            category,
            filtersData: {},
            sortData: {},
            initialSelectedValues: [],
            filterOptions: [],
            sortOptions: [],

            defaultSort: '',
            metaData: []
        },
        products: [],
        recommendationData: [],
        breadcrumbs: [{
            label: 'Home',
            href: '/',
        },
        {
            label: category.charAt(0).toUpperCase() + category.slice(1)
        }]
    }

    let filtersData = {}
    let catalogProducts = []
    let filtersConfig = []

    try {
        const modifiers = ['PRE_CART', 'RANDOM_DEFAULT_SWATCH']

        const fetcher = async () => {
            return Promise.all([
                getFilterConfigByCategory(category),
                getFiltersData(category, prismicRef),
                getCatalogCategoryProducts(category, modifiers)
            ])
        }

        const previewCacheKey = prismicRef && getPreviewCacheKey(prismicRef)
        const cacheKey = Cache.buildKey('category', category, modifiers, previewCacheKey)
        const expire = SHORT_REVALIDATE
        const response = await Cache.getRevalidatingCache(fetcher, cacheKey, expire)

        if (response) {
            filtersConfig = response[0] ? [...response[0]] : []
            // console.log(filtersConfig)
            filtersData = response[1] && { ...response[1] }
            catalogProducts = response[2] && [...response[2]]
        }
    } catch (error) {
        console.log(`[pages.category]: Error fetching product and filter data for category ${category} with message: ${error.message}`)
        Sentry.captureException(error)
    }

    // We need filtersData, if there is an error retrieving and we dont have cache
    // we try to return a partial response so the page can still load
    if (!isEmpty(filtersData) || !isEmpty(filtersConfig)) {
        const {
            sorters,
            defaultSort,
            products: productsContent,
            seo
        } = filtersData


        const { isV1 } = getExperiment(res.locals.experiments, 'EXP.ALGOLIA_LOGIC_ON_PLPS.DIS-155', res.locals.flagOverrides)
        // sort if V1, otherwise return the original list
        const sortedProductList = isV1 ? sortProductsByPriority(productsContent, category) : productsContent

        const { filters, sort } = retrieveParams(req.query, sorters, filtersConfig, defaultSort)
        const filterOptions = getFilterOptions(filtersConfig)

        const initialSelectedValues = getSelectedValues(filters)

        let customCanonical = `/${category}`
        if (initialSelectedValues.length === 1) {
            const { key, value } = initialSelectedValues[0]
            if (key && value) {
                const splitValue = split(value, ' ')
                customCanonical = `/${category}?${key}=${join(splitValue, '+')}`
            }
        }

        sortedProductList?.forEach(contentProduct => {
            const catalogProduct = catalogProducts?.find(({ productCode }) => (
                contentProduct.sku === productCode
            ))

            if (catalogProduct) {
                const product = formatFilterProduct({
                    contentProduct,
                    catalogProduct,
                    sorters,
                    filtersConfig
                })
                pageData.products.push(product)
            }
        })

        const { title, description } = getFilterMetaData(initialSelectedValues, seo)

        pageData = {
            ...pageData,
            title,
            description,
            customCanonical,
            filterContextData: {
                category,
                filtersData: filters,
                sortData: sort,
                initialSelectedValues,
                filterOptions,
                sortOptions: sorters,
                defaultSort,
                metaData: seo
            }
        }
    }

    const recommendationList = RECOMMENDATION_LISTS[category]

    if (recommendationList) {
        try {
            const [catalogProducts, beddingContent, furnitureContent] = await Promise.all([
                getTrendingItems(),
                getProductsData(CATEGORIES.BEDDING, prismicRef),
                getProductsData(CATEGORIES.FURNITURE, prismicRef)
            ])

            const productsContent = [...beddingContent, ...furnitureContent]
            pageData.recommendationData = catalogProducts.map(product => {
                const content = productsContent?.find(({ sku }) => product.productCode === sku)
    
                if (content) {
                    product.content = {
                        ...product.content,
                        ...content
                    }
                }
    
                return product
            })
        } catch (error) {
            pageData.recommendationData = []
            console.log(error)
        }
    }

    const faqsList = FAQS_LISTS[category]

    if (faqsList) {
        try {
            pageData.faqList = await getFaqsList(faqsList, prismicRef)
        } catch (error) {
            pageData.faqList = []
            console.log(error)
        }
    }

    const reviewList = REVIEWS_LISTS[category]

    if (reviewList) {
        try {
            pageData.reviewList = await getReviewsList(category, prismicRef)
        } catch (error) {
            pageData.reviewList = []
            console.log(error)
        }
    }

    return {
        props: buildServerSideProps(pageData, res.locals)
    }
})

export function getServerSideProps(context) {
    const { req, res } = buildServerSideContext(context)
    return router.run(req, res)
}

export default function CategoryPage(props) {
    return (
        <CategoryView {...props} />
    )
}
