import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { Button } from '@saatva-bits/pattern-library.components.button'

import { useFiltersState, useFiltersData } from '@/contexts/filters'
import {
    applySort,
    FILTER_PROPERTIES,
    getFilterMetaData,
    getSelectedFilters,
    getValuesByProperty,
    applyVariantFiltering
} from '@/utils/filters'
import { affirmRefresh } from '@/utils/affirm'
import FilterSection from '../FilterSection/FilterSection'
import ProductTiles from '../../ProductTiles/ProductTiles'
import CategoryHero from '@/components/CategoryHero/CategoryHero'
import CategorySubcategories from '@/components/CategorySubcategories/CategorySubcategories'

import styles from './FilterContent.module.scss'

const FilterContent = ({ products, category }) => {
    const { filterValues, selectedValues, sortBy } = useFiltersState()
    const { metaData, filterOptions } = useFiltersData()
    const { isDesktop } = useBreakpoints('desktop')
    const { getDiscounts } = useGetDiscounts()
    const loadMoreInt = isDesktop ? 50 : 15
    const [loadMore, setLoadMore] = useState(1)

    const { pageheader } = getFilterMetaData(selectedValues, metaData)

    // Values used for personalization
    const valuesGroups = {
        selectedSizes: getValuesByProperty(FILTER_PROPERTIES.sizes, selectedValues),
        selectedColors: getValuesByProperty(FILTER_PROPERTIES.colors, selectedValues)
    }

    const selectedFilters = getSelectedFilters(filterOptions, Object.values(filterValues))
    const filteredProducts = applyVariantFiltering(products, selectedFilters, getDiscounts)

    const sortedList = applySort(filteredProducts, sortBy)

    let productList = !isDesktop ? sortedList.slice(0, loadMore*loadMoreInt) : sortedList
    const productListLength = sortedList.length

    const handleLoadMoreOnClick = () => {
        setLoadMore((count) => count + 1)
        affirmRefresh(100)
    }

    const headerClassName = classNames('t-heading1 t-color', styles.pageheader)

    return (
        <>
            {selectedValues.length === 0 && (
                <>
                    <CategoryHero category={category} />
                    <CategorySubcategories category={category} />
                </>
            )}
            <section className={classNames('section', styles.filterSection)}>
                <div className="container">
                    {selectedValues.length > 0 && <h1 className={headerClassName}>{pageheader}</h1> }
                    <FilterSection totalResults={productListLength} products={products} />
                    <div className={classNames('row u-flexJustify--center u-marginBottom--2xl', styles.productTileContainer)}>
                        <ProductTiles
                            products={productList}
                            valuesGroups={valuesGroups}
                            category={category}
                        />
                    </div>
                    {loadMore < productListLength/loadMoreInt &&
                        <Button
                            kind="primary"
                            className={styles.loadMoreBtn}
                            onClick={handleLoadMoreOnClick}
                        >
                            Load More
                        </Button>
                    }
                </div>
            </section>
        </>
    )
}

FilterContent.propTypes = {
    products: PropTypes.array,
    category: PropTypes.string
}

export default FilterContent
