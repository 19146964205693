import React from 'react'
import PropTypes from 'prop-types'
import FilterOptions from '../FilterOptions/FilterOptions'
import DropdownPanel from '@/components/DropdownPanel/DropdownPanel'

import styles from './FiltersGroup.module.scss'

const MobileComponents = ({ accordionData }) => {
    return (
        <div className={styles.filtersMobile}>
            {accordionData.map(dropdown => (
                <div className={styles.filterSection} key={dropdown.title}>
                    <h2>{dropdown.title}</h2>
                    <div className={styles.filterSectionContent}>{dropdown.content}</div>
                </div>
            )
            )}
        </div>
    )
}

const DropdownComponents = ({accordionData}) => {
    return (
        <div className={styles.dropdownsContainer}>
            {accordionData.map(dropdown => (
                <DropdownPanel key={dropdown.title} title={dropdown.title} >
                    {dropdown.content}
                </DropdownPanel>
            )
            )}
        </div>
    )
}

const FiltersGroup = ({
    filters = {},
    products,
    isModal = false,
    handleFilterChange,
}) => {
    const accordionData = filters.map(filter => {
        const title = filter.label
        const content = (
            <FilterOptions
                key={filter.label}
                filter={filter}
                products={products}
                isModal={isModal}
                handleFilterChange={handleFilterChange}
            />
        )
        return {
            title,
            content,
        }
    })

    return isModal
        ? <MobileComponents accordionData={accordionData} />
        : <DropdownComponents  accordionData={accordionData} />
}


FiltersGroup.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    isModal: PropTypes.bool
}

export default FiltersGroup
