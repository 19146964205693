import React from 'react'
import PropTypes from 'prop-types'

import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'

import RightSetup from '@/components/RightSetup/RightSetup'
import ValuePropsSection from '@/components/ValuePropsSection/ValuePropsSection'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import Quiz from '@/components/QuizBedframe'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

const FurnitureContent = ({ category, reviews }) => {
    const { isV1 } = useExperiment('EXP.BED_FRAME_JEBBIT_QUIZ.PD-17485')

    return (
        <>
            { isV1 && <Quiz /> }
            <ReviewSection reviews={reviews} />
            { !isV1 && <RightSetup categoryName={category} /> }
            <ValuePropsSection configKey={category} background="contrast-1" />
            <FaqSection />
        </>
    )
}

FurnitureContent.propTypes = {
    category: PropTypes.string.isRequired
}

export default FurnitureContent
