import React from 'react'
import PropTypes from 'prop-types'

import { Accordion } from '@saatva-bits/pattern-library.components.accordion'
import { AccordionSet } from '@saatva-bits/pattern-library.components.accordion-set'
import FilterOptions from '../FilterOptions/FilterOptions'
import DropdownPanel from '@/components/DropdownPanel/DropdownPanel'
import InStockSwitch from '../InStockSwitch/InStockSwitch'

import styles from './FiltersGroup.module.scss'

const AccordionComponents = ({ accordionData }) => {
    return (
        <AccordionSet
            kind="unstyledSet"
            className={styles.accordionSet}
            accordionData={accordionData}
        >
            <Accordion
                className={styles.accordion}
                titleContainerClassName={styles.accordionTitleContainer}
                titleClassName={styles.accordionTitle}
                iconContainerClassName={styles.accordionIconContainer}
                iconClassName={styles.chevronIcon}
                contentContainerClassName={styles.accordionContent}
                headingLevel={4}
                expandIconName="expand"
                collapseIconName="expand"
                allowMultiOpen
                isOpenOnRender
            />
        </AccordionSet>
    )
}

const DropdownComponents = ({accordionData, displayInStockSwitch}) => {
    return (
        <div className={styles.dropdownsContainer}>
            {accordionData.map(dropdown => {
                if (dropdown.property === 'inStock') {
                    return displayInStockSwitch ? <InStockSwitch handleFilterChange={dropdown.handleFilterChange} key={dropdown.title}/> : null
                }

                return (
                    <DropdownPanel key={dropdown.title} title={dropdown.title}>
                        {dropdown.content}
                    </DropdownPanel>
                )
            })}
        </div>
    )
}

const FiltersGroup = ({
    filters = {},
    products,
    isModal = false,
    handleFilterChange,
    displayInStockSwitch
}) => {
    const accordionData = filters.map(filter => {
        const title = filter.label
        const content = (
            <FilterOptions
                key={filter.label}
                filter={filter}
                products={products}
                isModal={isModal}
                handleFilterChange={handleFilterChange}
            />
        )
        return {
            ...filter,
            title,
            content,
            handleFilterChange
        }
    })

    return isModal
        ? <AccordionComponents accordionData={accordionData.filter(ad => ad.property !== 'inStock')} />
        : <DropdownComponents  accordionData={accordionData} displayInStockSwitch={displayInStockSwitch}/>
}


FiltersGroup.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    isModal: PropTypes.bool
}

export default FiltersGroup
