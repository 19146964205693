import { SelectionProvider } from '@saatva-bits/pattern-library.modules.selection'
import { FiltersProvider } from '@/contexts/filters'

import CATEGORIES from '@/temp-configs/category-constants'
import Layout from '@/layouts/Default'
import FilterContent from './partials/FilterContent/FilterContent'
import FilterContentV2 from './partials/FiltersV2/FilterContent/FilterContent'

import categoryContentConfig from './content'
import { ScrollToTopButton } from '@saatva-bits/pattern-library.components.scroll-to-top-button'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

export default function CategoryView(props) {
    const {
        products,
        category,
        filterContextData,
        recommendationData,
        reviewList
    } = props

    const CategoryContent = categoryContentConfig[category]?.content
    const scrollToTopMobileOnly = category !== CATEGORIES.BEDDING
    const { isV1 } = useExperiment('EXP.PLP_BY_SUBCATEGORY.PD-17531')
    
    return (
        <Layout {...props}>
            <SelectionProvider productsData={products} defaultInStock={true}>
                <FiltersProvider contextData={filterContextData}>
                    {isV1 ? <FilterContentV2 products={products} category={category} /> : <FilterContent products={products} category={category} /> }
                </FiltersProvider>
                {CategoryContent &&
                    <CategoryContent
                        category={category}
                        recommendationData={recommendationData}
                        reviews={reviewList}
                    />
                }
                <ScrollToTopButton mobileOnly={scrollToTopMobileOnly} />
            </SelectionProvider>
        </Layout>
    )
}

