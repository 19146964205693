import CATEGORIES from '@/temp-configs/category-constants'
import productCodes from '@/temp-configs/product-codes'

export const subcategoriesConfig = {
    [CATEGORIES.MATTRESSES]: [
        {
            title: 'Hybrid Innerspring',
            url: '/mattresses?types=hybrid%2Cinnerspring',
            image: {
                productCode: productCodes.classic,
                altTag: 'Saatva Classic Mattress',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        },
        {
            title: 'Memory Foam',
            url: '/mattresses?types=memory+foam',
            image: {
                productCode: productCodes.loom,
                altTag: 'Loom & Leaf Mattress',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        },
        {
            title: 'Natural Latex',
            url: '/mattresses?types=latex',
            image: {
                productCode: productCodes.zenhaven,
                altTag: 'Zenhaven Latex Mattress',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        },
        {
            title: 'Adjustable Firmness',
            url: '/mattresses?types=adjustable',
            image: {
                productCode: productCodes.solaire,
                altTag: 'Solaire Adjustable Firmness Mattress',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'size'
                ],
                exclusions: {
                    size: [
                        'twin-xl',
                        'full',
                        'queen',
                        'king',
                        'split-king',
                        'cal-king',
                        'split-cal-king-half',
                        'split-cal-king',
                        'upper-flex-queen',
                        'upper-flex-king'
                    ]
                },
                selectedOptions: {
                    size: 'split'
                }
            }
        },
        {
            title: 'Kids & Baby',
            url: '/mattresses?types=kids+baby',
            image: {
                productCode: productCodes.crib,
                altTag: 'Crib Mattress',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        },
        {
            title: 'Pets',
            url: '/mattresses?types=pets',
            image: {
                productCode: productCodes.dogBed,
                altTag: 'Saatva Dog Bed',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'fabric'
                ],
                exclusions: {},
                selectedOptions: {
                    fabric: 'taupe'
                }
            }
        }
    ],
    [CATEGORIES.FURNITURE]: [
        {
            title: 'Bed Frames',
            url: '/furniture?types=bed+frames',
            image: {
                productCode: productCodes.amalfi,
                altTag: 'Amalfi',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'fabric'
                ],
                exclusions: {},
                selectedOptions: {
                    fabric: 'taupe'
                }
            }
        },
        {
            title: 'Adjustable Bases',
            url: '/furniture?types=adjustable+bases',
            image: {
                productCode: productCodes.adjustableBasePlus,
                altTag: 'Saatva Adjustable Base Plus',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'size'
                ],
                exclusions: {
                    size: [
                        'split-queen-half',
                        'split-cal-king-half',
                        'upper-flex-queen-half',
                        'upper-flex-king-half',
                        'split',
                        'upper-flex',
                        'standard'
                    ]
                },
                selectedOptions: {
                    size: 'cal-king'
                }
            }
        },
        {
            title: 'Foundation & Bunkie Board',
            url: '/furniture?types=mattress+foundation',
            image: {
                productCode: productCodes.foundation,
                altTag: 'Foundation & Bunkie Board',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'size',
                    'foundationHeight'
                ],
                exclusions: {
                    size: [
                        'twin',
                        'twin-xl',
                        'full',
                        'queen',
                        'split-queen-half',
                        'split-queen',
                        'king',
                        'split-king',
                        'cal-king',
                        'split-cal-king-half',
                        'split-cal-king',
                        'upper-flex'
                    ]
                },
                selectedOptions: {
                    size: 'standard',
                    foundationHeight: '8.75'
                }
            }
        },
        {
            title: 'Bedroom Chairs',
            url: '/furniture?types=chairs',
            image: {
                productCode: productCodes.lunaSwivelChair,
                altTag: 'Luna Swivel Chair',
                imageDescriptors: [
                    'silo-angle'
                ],
                attributeSets: [
                    'fabric'
                ],
                exclusions: {},
                selectedOptions: {
                    fabric: 'linen'
                }
            }
        },
        {
            title: 'Benches & Ottomans',
            url: '/furniture?types=benches+ottomans',
            image: {
                productCode: productCodes.constanceTuftedOttoman,
                altTag: 'Constance Tufted Ottoman',
                imageDescriptors: [
                    'silo-angle'
                ],
                attributeSets: [
                    'fabric'
                ],
                exclusions: {},
                selectedOptions: {
                    fabric: 'taupe'
                }
            }
        },
        {
            title: 'Rugs',
            url: '/furniture?types=rugs',
            image: {
                productCode: productCodes.ternaRug,
                altTag: 'Terna Handcrafted Bedroom Rug',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'color'
                ],
                exclusions: {},
                selectedOptions: {
                    color: 'graphite'
                }
            },
        },
        {
            title: 'Candles',
            url: '/furniture?types=candles',
            image: {
                productCode: productCodes.scentedVotives,
                altTag: 'Scented Votives',
                imageDescriptors: [
                    'lifestyle'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        }
    ],
    [CATEGORIES.BEDDING]: [
        {
            title: 'Mattress Pad & Toppers',
            url: '/bedding?types=mattress+pad+and+toppers',
            image: {
                productCode: productCodes.featherbedMattressTopper,
                altTag: 'Down Alternative Featherbed Mattress Topper',
                imageDescriptors: [
                    'silo-profile'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        },
        {
            title: 'Pillows',
            url: '/bedding?types=pillows+type',
            image: {
                productCode: productCodes.pillow,
                altTag: 'Saatva Latex Pillow',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        },
        {
            title: 'Sheets',
            url: '/bedding?types=sheets+type',
            image: {
                productCode: productCodes.percaleSheets,
                altTag: 'Percale Sheet Set',
                imageDescriptors: [
                    'messy'
                ],
                attributeSets: [
                    'color'
                ],
                exclusions: {},
                selectedOptions: {
                    color: 'white'
                }
            }
        },
        {
            title: 'Pillowcases',
            url: '/bedding?types=pillowcases+and+shams',
            image: {
                productCode: productCodes.linenPillowcase,
                altTag: 'Linen Pillowcase Pair',
                imageDescriptors: [
                    'lifestyle-messy'
                ],
                attributeSets: [
                    'color'
                ],
                exclusions: {},
                selectedOptions: {
                    color: 'terra'
                }
            }
        },
        {
            title: 'Comforters',
            url: '/bedding?types=comforters',
            image:     {
                productCode: productCodes.lightweightAlternativeComforter,
                altTag: 'Lightweight Down Alternative Comforter',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            }
        },
        {
            title: 'Duvet Covers',
            url: '/bedding?types=duvet+covers',
            image: {
                productCode: productCodes.percaleDuvet,
                altTag: 'Percale Duvet Cover Set',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'color'
                ],
                exclusions: {},
                selectedOptions: {
                    color: 'ivory'
                }
            }
        },
        {
            title: 'Blankets & Quilts',
            url: '/bedding?types=blankets+and+quilts',
            image: {
                productCode: productCodes.weightedBlanket,
                altTag: 'Weighted Blanket',
                imageDescriptors: [
                    'on-white'
                ],
                attributeSets: [
                    'color'
                ],
                exclusions: {},
                selectedOptions: {
                    color: 'taupe'
                }
            }
        },
        {
            title: 'Towels',
            url: '/bedding?types=towels',
            image: {
                productCode: productCodes.plushTowels,
                altTag: 'Plush Towel Collection',
                imageDescriptors: [
                    'colorways'
                ],
                attributeSets: [],
                exclusions: {},
                selectedOptions: {}
            },
        },
        {
            title: 'Eye Masks',
            url: '/bedding?types=eye+masks',
            image: {
                productCode: productCodes.weightedSilkEyeMask,
                altTag: 'Weighted Silk Eye Mask',
                imageDescriptors: [
                    'silo'
                ],
                attributeSets: [
                    'color'
                ],
                exclusions: {},
                selectedOptions: {
                    color: 'sand'
                }
            }
        }
    ]
}
