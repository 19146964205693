import { useMemo, useState, useEffect } from 'react'
import classNames from 'classnames'
import CATEGORIES from '@/temp-configs/category-constants'

import { handleTag, ProductCTA } from '@saatva-bits/pattern-library.modules.detail-modal'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'

import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'
import { useProductState, useProductData, useAttributeOptions, ProductSelectors } from '@saatva-bits/pattern-library.modules.selection'
import { useGetDiscounts, applyDiscounts } from '@saatva-bits/pattern-library.modules.promotions'

import AddToCartButton from '@/components/ButtonAddToCart'
import DetailModalVariant from '@/components/DetailModalVariant'
import { useFiltersData } from '@/contexts/filters'
import { AuxContentTile, ValuePropsTile } from '@/views/Category/partials/FiltersV2/AuxContentTile/AuxContentTile'
import { tiles } from './ContentConfig'
// import { usePriceRange } from '@/hooks/usePriceRange'

import { getSwatches, getImages as getImagesFunction, getSwatchFromSelections } from '@/utils/product'
import { affirmRefresh } from '@/utils/affirm'

import styles from './ProductTiles.module.scss'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const commonImageProps = {
    widths: { mobile: 348 },
    isUniversal: true,
    imgixDomain: IMGIX_URL,
    lazyLoad: true,
    /** Override the lazyload offset added by the detail product tile from bit  */
    lazyLoadOffset: null
}

const ProductTile = ({ product, valuesGroups, hideSwatchesImages }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { isV1: plpTestOn } = useExperiment('EXP.PLP_BY_SUBCATEGORY.PD-17531')

    const { filterOptions } = useFiltersData()

    const colorMaterialOptions = useMemo(() => filterOptions.filter(option => option.property === 'colors' || option.property === 'materials'), [filterOptions])
    const isBeddingCategory = product.category.toLowerCase() === CATEGORIES.BEDDING
    const isBundleCategory = product.category.toLowerCase() === CATEGORIES.BUNDLES
    const showAddToCartUI = isBeddingCategory || isBundleCategory


    // TODO: use once the filter works filtering by variants
    // const priceRange = usePriceRange(product.variants)
    const priceRange = {
        fullPriceMin: product.lowestPrice,
        fullPriceMax: product.highestPrice,
        finalPriceMin: product.lowerPrice || 0,
        finalPriceMax: product.upperPrice || 0,
    }

    let productData = useMemo(() => {
        return {
            ...product,
            ...product.content,
            selectedSizes: valuesGroups.selectedSizes
        }
    }, [product, valuesGroups.selectedSizes])

    const onAddToCart = () => {
        setModalOpen(false)
    }

    const customOptionProps = {
        'bathSet': {
            toggleProps: {
                modifyOptionLabel: (label) => { return label.replace('Bath', `\nBath`) },
                autoFitColumnLayoutOverride: true
            }
        },
    }

    const attributesWithCustomSetup = ['color', 'fabric', 'scent', 'quantity']
    const hiddenAttributes = ['quantity']

    productData.options.forEach(option => {
        // Create customProps to modify default behavior specific attributes
        if (!attributesWithCustomSetup.includes(option.code)) {
            customOptionProps[option.code] = { displayType: 'toggle' }
            !showAddToCartUI && hiddenAttributes.push(option.code)
        }
        // Create customProps to modify default selectors label based on category
        else if (!isBeddingCategory) {
            customOptionProps[option.code] = {
                toggleProps: {
                    label: option.displayName,
                    isCustomLabel: true
                }
            }
        }
    })

    const addToCartSection = (
        <div className={styles.addToCartSection}>
            {showAddToCartUI &&
                <AddToCartButton
                    productCode={productData.productCode}
                    onAddToCart={onAddToCart}
                    hasQtySelector={true}
                >
                    Add to Cart
                </AddToCartButton>
            }
            <ProductCTA
                productCode={productData.productCode}
                selectionDeps={{ useProductState }}
                shopCta={!showAddToCartUI && productData.shopCta}
                showLearnMoreCta={showAddToCartUI} />
        </div>
    )

    const buystack = (
        <ProductSelectors
            className={styles.detailModalSelectors}
            productCode={productData.productCode}
            customOptionProps={customOptionProps}
            shortDropdowns={true}
            hiddenAttributes={hiddenAttributes} />
    )


    // TODO: switch to ARDAD
    const getImages = getImagesFunction(product.productCode, product.content.title, product.category.toLowerCase(), commonImageProps, hideSwatchesImages)

    let swatches = getSwatches(product.options, product.content.defaultSwatch, product.productCode.length)

    const swatchOverride = getSwatchFromSelections(colorMaterialOptions, valuesGroups, product.matchingVariants)

    if (swatches && swatchOverride) swatches = { ...swatches, initialSelection: swatchOverride }

    const tag = handleTag(product.content.isNew, product.content.tag)

    const affirmPrice = product.category.toLowerCase() !== CATEGORIES.BEDDING ? { price: priceRange.finalPriceMin } : null

    const containerClassNames = classNames(styles.productTileContainer, {
        'u-marginBottom--2xl': !swatches && !affirmPrice,
        'u-marginBottom--xs': swatches
    })

    const modalContainerProps = {
        closeButtonClasses: styles.closeButton,
        modalClasses: styles.detailModalContainer
    }

    return <>
        <DetailProductTile
            imageCTA={{ text: 'View Details', onClick: () => setModalOpen(true) }}
            title={{ text: product.content.title, href: product.url, titleClassName: styles.title }}
            subtitle={{ text: product.content.subtitle, className: styles.subtitle }}
            priceRange={priceRange}
            imageHref={product.url}
            affirm={affirmPrice}
            getImages={getImages}
            swatches={swatches}
            containerClassName={containerClassNames}
            imageBadgeLeft={tag && { text: tag }}
            isV2={plpTestOn}
        />
        <DetailModalVariant
            isOpen={modalOpen}
            closeHandler={() => setModalOpen(false)}
            productData={productData}
            buystack={buystack}
            addToCartSection={addToCartSection}
            promotionDeps={{
                useGetDiscounts,
                applyDiscounts
            }}
            selectionDeps={{
                useProductData,
                useProductState,
                useAttributeOptions
            }}
            modalContainerProps={modalContainerProps}
        />
    </>
}

/**
 * @param {object} props
 * @param {any[]} props.products
 * @param {{ selectedSizes: string[]?, selectedColors: string[]? }} props.valuesGroups - Values selected by user that change content shown in page
 * @param {string} props.category - Category of the products
 * @param {Function} props.clearFilters - Function to clear filters
 * @param {boolean} props.hideSwatchesImages - Hide swatches images
 */
export default function ProductTiles({ products, valuesGroups, category, clearFilters, hideSwatchesImages }) {
    const { isV1: plpTestOn } = useExperiment('EXP.PLP_BY_SUBCATEGORY.PD-17531')

    useEffect(() => {
        affirmRefresh(100)
    })

    if (products.length <= 0) {
        return <div className='col col--xs-12 col--sm-8 col--md-6 col--lg-4'>
            <div className={styles.noProductsTile}>
                <h2>We couldn’t find any available products with the filters you selected. Please try clearing your filters to see available options. <button className={styles.clearButton} onClick={clearFilters}>Clear Filters</button></h2>
            </div>
        </div>
    }

    const contentTiles = category && tiles[category.toLowerCase()]

    return products.reduce((productTiles, product, i) => {
        const productTile = (
            <div key={product.productCode} className={plpTestOn ? 'col col--xs-6 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl' : 'col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl'}>
                <ProductTile
                    product={product}
                    valuesGroups={valuesGroups}
                    hideSwatchesImages={hideSwatchesImages}
                />
            </div>
        )

        // add a content tile every 4 tiles and dont repeat the tiles
        const addContentTile = contentTiles && (i > 0) && (i % 4 === 0) && (i / 4 - 1 < contentTiles.length)

        if (plpTestOn && addContentTile) {
            const contentTileConfig = contentTiles[Math.round(i / 4 - 1)]

            const ContentTileComponent = contentTileConfig.valueProps ? ValuePropsTile : AuxContentTile

            const contentTile = (
                <div key={contentTileConfig.id} className='col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl'>
                    <ContentTileComponent {...contentTileConfig} />
                </div>
            )

            return [...productTiles, contentTile, productTile]
        }

        return [...productTiles, productTile]
    }, [])
}
