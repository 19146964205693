import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFiltersState, useFiltersData } from '@/contexts/filters'
import { getSelectedFilters, calculateValidFilterOptions } from '@/utils/filters'

import FormCheckboxGroup from '@/components/FormCheckboxGroup'

import styles from './FilterOptions.module.scss'

const FilterOptions = ({
    filter = {},
    products,
    isModal = false,
    handleFilterChange = () => { },
}) => {
    const filtersState = useFiltersState()
    const { filterOptions: filterConfig, sortOptions, defaultSort } = useFiltersData()
    const { filterValues, tempFilters, sortByLabel } = filtersState

    const sortValue = useMemo(() => (
        sortOptions.find(sort => sort.value !== defaultSort && sort.label === sortByLabel)?.value
    ), [sortOptions, defaultSort, sortByLabel])

    let validCheckBoxOptions = []

    const tempFilterValues = {}
    Object.keys(tempFilters).forEach(key => {
        tempFilterValues[key] = {
            property: key,
            values: tempFilters[key] || []
        }
    })

    // If we are in the modal we need to take into account the temp values to disable properly
    // on desktop the changes are immediate so its more straightforward
    const filterValuesToCheck = isModal ? tempFilterValues : filterValues
    const checkedValues = isModal ? tempFilterValues[filter.property].values : filtersState[filter.property].values

    const selectedFilters = getSelectedFilters(filterConfig, Object.values(filterValuesToCheck))
    const filteredSelectedFilters = selectedFilters.filter((selectedFilter) => selectedFilter.property !== filter.property)
    const validFilterOptions = calculateValidFilterOptions(products, filteredSelectedFilters, selectedFilters)
    validCheckBoxOptions = validFilterOptions[filter.property] || []

    const filterOptions = filter.options.map(option => {
        let isValid = true

        isValid = option.matchingValues.some(value => validCheckBoxOptions.includes(value.toLowerCase()))

        // Only disable if it has not been selected beforehand
        const isChecked = checkedValues.includes(option.value)
        const isDisabled = !isChecked && !isValid
        return {
            id: `${isModal ? 'modal' : 'regular'}_${option.value}`,
            ...option,
            disabled: isDisabled
        }
    })

    return (
        <FormCheckboxGroup
            dataSrc={filterOptions}
            initialValues={checkedValues}
            required={0}
            className={styles.checkBoxGroup}
            checkboxClassName={styles.filterCheckbox}
            onSelectionUpdate={e => handleFilterChange(e, filter.property)}
            sortValue={sortValue}
            filter={filter}
            products={products}
            selectedFilters={selectedFilters}
        />
    )
}

FilterOptions.propTypes = {
    filter: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    isModal: PropTypes.bool
}

export default FilterOptions
